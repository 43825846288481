import { useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetNameplateData } from "../../../types";

export const useCreateAssetNameplateData = () => {
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: (assetNameplateData: Partial<AssetNameplateData>) => {
      return AcceleratorApi.AssetNameplateData.create(assetNameplateData);
    }
  });

  return {
    createAssetNameplateData: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
