import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, TagMapping, TagMappingResponse } from "../../../types";

interface Variables {
  previousValue: TagMapping;
  nextValue: Partial<TagMapping>;
}

export const useUpdateTagMapping = () => {
  const queryOptions: UseMutationOptions<TagMappingResponse, HTTPError, Variables> = {
    mutationFn: ({ previousValue, nextValue }: Variables) =>
      AcceleratorApi.TagMappings.update(previousValue, nextValue)
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    updateTagMapping: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
