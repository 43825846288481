import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetSubTypeListResponse } from "../../../types";
import { assetSubTypesListQueryKey } from "../query-cache";

export const useGetAssetSubTypes = ({
  enabled
}: {
  enabled?: boolean;
} = {}) => {
  const queryKey = assetSubTypesListQueryKey();

  const { isLoading, isSuccess, isError, data, error, refetch } =
    useQuery<AssetSubTypeListResponse>({
      queryKey,
      queryFn: () => AcceleratorApi.AssetSubTypes.getAssetSubTypes(),
      enabled,
      select: (data) => {
        data?.data?.sort((a, b) =>
          a.name?.toLocaleLowerCase().localeCompare(b.name?.toLocaleLowerCase())
        );
        return data;
      }
    });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetSubTypes: data?.data,
    error,
    refetch
  };
};
