import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { AssetProperty, AssetPropertyDocumentResponse, HTTPError } from "../../../types";
import { AcceleratorApi, useGetAssetPropertyById } from "../../..";
import { assetPropertyByIdQueryKey } from "../query-cache";

/**
 * Hook to update an AssetProperty
 */
export const useUpdateAssetPropertyById = (
  options: UseMutationOptions<AssetPropertyDocumentResponse, HTTPError, Partial<AssetProperty>>,
  assetPropertyId: string
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restQueryOptions } = options;
  const { assetProperty } = useGetAssetPropertyById(assetPropertyId);
  const queryOptions: UseMutationOptions<
    AssetPropertyDocumentResponse,
    HTTPError,
    Partial<AssetProperty>
  > = {
    mutationFn: (updates) =>
      AcceleratorApi.AssetProperties.patch(assetProperty.id, assetProperty, updates),
    onSuccess: async (data, variables, context) => {
      await queryClient.setQueryData(assetPropertyByIdQueryKey(data.data.id), () => data);
      await onSuccess(data, variables, context);
    },
    ...restQueryOptions
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    assetProperty: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateAssetProperty: mutateAsync
  };
};
