import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { AlarmMode, AlarmModeDocumentResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";
import { alarmModeByIdQueryKey } from "../query-cache";

type Variables = {
  previousAlarmMode: AlarmMode;
  alarmMode: Partial<AlarmMode>;
};

export const useUpdateAlarmModeById = (
  queryOptions: UseMutationOptions<AlarmModeDocumentResponse, HTTPError, Variables>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restQueryOptions } = queryOptions;
  return useMutation({
    mutationFn: ({ previousAlarmMode, alarmMode }) =>
      AcceleratorApi.AlarmModes.patch(previousAlarmMode, alarmMode),
    onSuccess: async (data, variables, context) => {
      await queryClient.setQueryData(alarmModeByIdQueryKey(data.data.id), () => data);
      await onSuccess(data, variables, context);
    },
    ...restQueryOptions
  });
};
