import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, SubAreaListResponse } from "../../../types";
import { subAreasListQueryKey } from "../query-cache";

export const useGetSubAreas = () => {
  const queryKey = subAreasListQueryKey();

  const queryOptions: UseQueryOptions<SubAreaListResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.SubAreas.get(),
    select: (data) => {
      data?.data?.sort((a, b) =>
        a.name?.toLocaleLowerCase().localeCompare(b.name?.toLocaleLowerCase())
      );
      return data;
    }
  };

  const { isLoading, isSuccess, isError, data, error, refetch, isFetching } =
    useQuery(queryOptions);

  return {
    loading: isLoading,
    isFetching,
    isSuccess,
    isError,
    subAreas: data?.data,
    error,
    refetch
  };
};
