import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AlarmModeListResponse } from "../../../types";
import { alarmModesListQueryKey } from "../query-cache";

export const useGetAlarmModes = ({ size, enabled }: { size?: number; enabled?: boolean } = {}) => {
  const queryKey = alarmModesListQueryKey(size);

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery<AlarmModeListResponse>({
    queryKey,
    queryFn: () => AcceleratorApi.AlarmModes.get(size),
    enabled
  });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    alarmModes: data?.data,
    error,
    refetch
  };
};
