import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { Asset, AssetResponse, AssetListResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";
import { assetsListQueryKey, assetByIdQueryKey, assetSearchByIdQueryKey } from "../query-cache";

type Variables = {
  previousAsset: Asset;
  asset: Partial<Asset>;
};

type Options = UseMutationOptions<AssetResponse, HTTPError, Variables>;

export const useUpdateAsset = (options: Options = {}) => {
  const queryClient = useQueryClient();
  const queryOptions: Options = {
    mutationFn: ({ previousAsset, asset }: Variables) =>
      AcceleratorApi.Assets.updateAsset(previousAsset, asset),
    ...options,
    onSuccess: (asset: AssetResponse, variables, context) => {
      queryClient.setQueryData<AssetListResponse | undefined>(
        assetsListQueryKey(),
        (currentState: AssetListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: currentState.data.map((state) =>
              state.id === asset.data.id ? asset.data : state
            )
          };
        }
      );
      queryClient.invalidateQueries<AssetResponse>(assetByIdQueryKey(asset.data.id));
      queryClient.invalidateQueries<AssetResponse>(assetSearchByIdQueryKey(asset.data.id));
      options?.onSuccess(asset, variables, context);
    }
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    asset: data?.data,
    isSuccess,
    error: error,
    loading: isLoading,
    updateAsset: mutateAsync
  };
};
