import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { alarmModeByIdQueryKey } from "../query-cache";

export const useGetAlarmModeById = (alarmModeId: string) => {
  const { isLoading, isSuccess, isError, data, error } = useQuery({
    queryKey: alarmModeByIdQueryKey(alarmModeId),
    queryFn: () => AcceleratorApi.AlarmModes.getById(alarmModeId),
    enabled: Boolean(alarmModeId)
  });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    alarmMode: data?.data,
    error
  };
};
