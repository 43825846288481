import { UseMutationOptions, useMutation } from "react-query";
import { AlarmThreshold, HTTPError, AlarmThresholdDocumentResponse } from "../../../types";
import { AcceleratorApi } from "../../..";
import { extractHttpErrorMessage } from "../../utils/http-error";

type Options = UseMutationOptions<
  AlarmThresholdDocumentResponse,
  HTTPError,
  Partial<AlarmThreshold>
>;

export const useCreateAlarmThreshold = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: (alarmThreshold) => AcceleratorApi.AlarmThreshold.create(alarmThreshold),
    onError: (error) => extractHttpErrorMessage(error),
    ...options
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createAlarmThreshold: mutateAsync,
    alarmThreshold: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
