import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { PlantsListResponse } from "../../../types";
import { plantsListQueryKey } from "../query-cache";

export const useGetPlants = () => {
  const queryKey = plantsListQueryKey();

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery<PlantsListResponse>({
    queryKey,
    queryFn: () => AcceleratorApi.Plants.get(),
    select: (data) => {
      data?.data?.sort((a, b) =>
        a.name?.toLocaleLowerCase().localeCompare(b.name?.toLocaleLowerCase())
      );
      return data;
    }
  });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    plants: data?.data,
    error,
    refetch
  };
};
